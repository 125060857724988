@import 'src/styles';

.modalOverlay {
  position: fixed;
  inset: 0;
  background: rgba(24, 27, 38, 0.25);
  z-index: 110;

  .modalPopup {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    max-height: 90vh;
    width: 100%;
    max-width: $maxWidthDefault;
    padding: 60px 20px 15px;
    background-color: $white;
    border-radius: 40px 40px 0 0;
    border: 0;
    outline: none;

    .crossButton {
      position: absolute;
      top: 15px;
      right: 15px;
      width: 28px;
      height: 28px;
      padding: 0;

      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        translate: -50% -50%;
        height: 24px;
        width: 1px;
        background-color: $primaryText;
        border-radius: 1px;
      }

      &::before {
        rotate: 45deg;
      }

      &::after {
        rotate: -45deg;
      }
    }
  }
}