:root {
  --primaryColor: #6a52ff;
  /* Main */
  --primaryColorLight: #E0DBFF;
  /* Tint */
  --secondaryColor: #52dcaa;
  /* Accent */
  --secondaryColorLight: #52dcaa75;
  --primaryText: #181B26;
  /* Font */
  --mainBg: #f7f7f7;
  /* Background */
  --primaryTextLight: #2d2d2d40;
  --maxButtonWidth: 325px;
  --titleText: #6a52ff;
  --secondaryText: white;
  --secondaryBg: white;
  /* Quetions */
  --feedbackStarColor: var(--primaryColor);
  --boxShadow: none;
  --injectedPaymentMethodActiveColor: var(--primaryColor);
  --titleH2Color: var(--primaryText);
  --questionHighlightTitleColor: var(--primaryColor);
  --answerTitleTextAlign: center;
  --buttonBackground: var(--primaryColor);
  --preloaderColor: #666666;
  --questionActiveTitleBorderColor: var(--primaryColor);
  --questionIconCheckColor: var(--primaryColor);
  --questionActiveTitleBgColor: white;

  --paymentModalLineThroughPriceColor: var(--primaryColor);
  --colorBorderField: #e0e0e0;
  --inputCaretColor: var(--primaryColor);

  --titleH1Color: var(--primaryColor);

  --plansPrimaryColor: var(--primaryColor);
  --plansPrimaryBorderColor: var(--primaryColor);
  --plansPrimaryBg: var(--secondaryBg);

  --plansPrimaryTitleColor: var(--primaryColor);

  --plansInactivePriceColor: var(--primaryColor);

  --plansInactiveColor: var(--primaryColorLight);
  --plansBadgeColor: var(--secondaryColor);

  --plansAccentBorderRadius: 15px;
  --plansContainerBorderRadius: 15px;

  --fixedButtonDiscountColor: var(--primaryColor);

  --checkboxMarkCheckedBackground: var(--primaryColor);

  --checkboxMarkBackground: var(--primaryColor);

  --payModalPrimaryColor: var(--primaryColor);
  --paymentModalPersonalDiscountColor: var(--secondaryColor);

  --moneyBackIconColor: var(--primaryColor);

  --moneyBackBackgroundColor: var(--white);
  --moneyBackTitleColor: var(--titleText);

  --successTextColor: var(--mineShaft);
  --storeStatsStarColor: var(--primaryColor);

  --footerTextColor: var(--mineShaft);
  --createAccountSuccessPopupSubtitle: #181B26;

  --paymentOverlay: #f7f7f7;
  --cookieModalIcon: #E0E0E0;
  --cookieModalTextColor: #2D2D2D;
  --cookieModalBg: var(--white);
  --cookieModalPrimary: var(--primaryColor);
  --cookieModalFootnoteText: rgba(#2D2D2D, 0.40);


  --soDiscountBlockBg: #E1EEEF;
  --soDiscountImageBg: #FFF;
  --soDiscountAccentTextColor: var(--secondaryColor);
  --soCurrentDiscountTextColor: #181B26;
  --soPrevDiscountTextColor: #181B26;
  --soInfoOfferBorderRadius: 8px;

  --soReservedPriceBg: #09737D;

  --soInfoOfferTextBg: #F4F4F4;
  --soInfoAccentTextColor: #09737D;
}

:root[data-theme='light'] {
  --primaryColor: #09737D;
  /* Main */
  --primaryColorLight: #75B1B6;
  /* Tint */
  --secondaryColor: #09737D;
  /* Accent */
  --secondaryColorLight: #52dcaa75;
  --primaryText: #181B26;
  --titleFontFamily: 'Playfair Display';
  --textFontFamily: 'Proxima Nova';
  --fontFamily: 'Proxima Nova';
  --secondaryFontFamily: 'Playfair Display';
  /* Font */
  --mainBg: #F4F4F4;
  /* Background */
  --primaryTextLight: #2d2d2d40;
  --titleText: #6a52ff;
  --secondaryText: white;
  --secondaryBg: white;
  /* Quetions */
  --boxShadow: none;
  --injectedPaymentMethodActiveColor: var(--primaryColor);
  --titleH2Color: var(--primaryText);
  --questionHighlightTitleColor: var(--primaryColor);
  --answerTitleTextAlign: center;
  --buttonBackground: #181B26;
  --preloaderColor: #666666;
  --questionActiveTitleColor: var(--primaryText);
  --questionTitleFontWeight: 400;
  --moneyBackTitleColor: var(--primaryColor);
  --questionActiveTitleBorderColor: #09737D;
  --questionIconCheckColor: #09737D;
  --questionActiveTitleBgColor: #E1EEEF;

  --colorBorderField: #e0e0e0;
  --inputCaretColor: var(--primaryColor);

  --titleH1Color: var(--primaryColor);

  --plansPrimaryColor: #09737D;
  --plansPrimaryBorderColor: #09737D;
  --plansPrimaryBg: #E1EEEF;

  --plansPrimaryTitleColor: #181B26;

  --plansInactivePriceColor: rgba(9, 115, 125, 0.5);

  --plansInactiveColor: #E1EEEF;
  --plansBadgeColor: #F38375;

  --plansAccentBorderRadius: 0 8px 8px 0;
  --plansContainerBorderRadius: 8px;

  --fixedButtonDiscountColor: var(--primaryColor);

  --checkboxMarkCheckedBackground: var(--primaryColor);

  --checkboxMarkBackground: var(--primaryColor);

  --payModalPrimaryColor: var(--plansPrimaryColor);
  --paymentModalLineThroughPriceColor: var(--plansPrimaryColor);
  --paymentModalPersonalDiscountColor: var(--plansPrimaryColor);

  --moneyBackIconColor: var(--primaryColor);

  --moneyBackBackgroundColor: var(--white);
  --moneyBackTitleColor: var(--primaryText);
  --maxButtonWidth: 335px;

  --successTextColor: var(--mineShaft);
  --storeStatsStarColor: var(--primaryColor);

  --feedbackStarColor: #F38375;

  --footerTextColor: var(--mineShaft);


  --paymentOverlay: #f7f7f7;

  --faceReadingLinkColor: var(--primaryText);
  --faceReadingLinkMarginTop: 10px;
  --faceReadingButtonColor: white;
  --faceReadingProgressNumberColor: var(--primaryText);
  --faceReadingProgressBackgroundColor: #181B26;

  --errorPopupPrimaryColor: var(--primaryText);

  /* ---- */
  --buttonBorderRadius: 6px;
  --buttonFontFamily: var(--textFontFamily);
  --questionAnswerBorderRadius: 8px;
  --selectionsHintFontStyle: normal;

  --progressCurrentStepPointColor: #f4dddb;
  --progressTextLight: rgb(24, 27, 38, 0.6);

  --sideBarMenuBgColor: var(--mainBg);

  --emailInputColor: rgba(24, 27, 38, 0.60);
  --emailInputBgColor: white;
  --emailInputTextColor: var(--primaryText);
  --emailInputTextFontFamily: var(--textFontFamily);
  --emailInputTextFontWeight: 600;
  --emailNotificationColor: #a3a4a8;

  --magicNotActiveGoalBgColor: #E1E1E1;
  --magicNotActiveGoalBorder: none;
  --magicNotActiveGoalIconColor: #919296;
  --magicTextsColor: var(--primaryText);
  --magicGoalTextsOpacity: var(--primaryText);
  --magicGoalTextsOpacity: 0.4;

  --progressBarActiveColor: #181B26;
  --progressCurrentStepPointColor: #FFFFFF;
  --progressBarBgColor: #FFF;
  --progressBarPointColor: #F4F4F4;
  --progressBarPointBorder: 5px solid #FFF;

  --soDiscountAccentTextColor: #F38375;
}