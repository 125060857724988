@import "core/styles";

$blackColor: #000000;
$mainBgColor: var(--mainBg);

$mineShaft: #181B26;
$primaryText: var(--primaryText);

$boxShadow: var(--boxShadow);
$shadowColor: rgba(183, 181, 170, 1);
$buttonTitleShadow: none;

$featureYouGetBg: var(--featureYouGetBg);

$fontFamily: var(--fontFamily);

$primaryColor: var(--primaryColor);
$secondaryColor: var(--secondaryColor);
$colorError: #F38375;

$quotesIconColor: transparent;

$checkboxMarkBackground: var(--checkboxMarkBackground);
$checkboxMarkCheckedBackground: var(--checkboxMarkCheckedBackground);

$injectedPaymentPrimaryColor: var(--injectedPaymentPrimaryColor);

$maxButtonWidth: var(--maxButtonWidth);

$engVersionPrimary: $mineShaft;

// sections
$magicGoalsIconColor: var(--primaryColor);

// email
$emailNotificationIconColor: var(--primaryColor);
$emailInputColor: var(--emailInputColor, --primaryColor);
$emailInputBgColor: var(--emailInputBgColor, #F7F7F7);
$emailInputTextColor: var(--emailInputTextColor, rgba($mineShaft, .8));
$emailNotificationColor: var(--emailNotificationColor, $mineShaft);

$emailButtonDisabledBgColor: #E1E1E1;
$emailButtonDisabledOpacity: 1;

// questions
$questionTitleFontSize: $fontSize26;
$questionTitleAdaptiveFontSize: $fontSize24;

$questionTextFontSize: $fontSize18;
$questionTextAdaptiveFontSize: $fontSize16;

$questionTitleColor: var(--primaryText);
$questionSubTitleColor: var(--primaryText);
$questionActiveTitleColor: var(--questionActiveTitleColor, --primaryColor);
$questionActiveTitleBorderColor: var(--questionActiveTitleBorderColor);
$questionIconCheckColor: var(--questionIconCheckColor);
$questionActiveTitleBgColor: var(--questionActiveTitleBgColor);
$questionTitleFontWeight: $fwn;
$questionActiveFontWeight: var(--questionTitleFontWeight, $fwb);
$questionRectangleAnswerPadding: 15px 5px;

$questionAnswerItemBoxShadow: none;

$questionTitleStyles: (
  padding: 4vh 0 3.5vh
);

// buttons
$buttonBackground: var(--buttonBackground);
$buttonFontFamily: var(--buttonFontFamily, $fontFamily);
$buttonBorderRadius: var(--buttonBorderRadius);
$buttonTitleColor: #FFFFFF;

$skipBtnColor: $mineShaft;

// links
$preloaderColor: var(--preloaderColor);

// form
$colorBorderField: $grayLight;
$inputCaretColor: $mineShaft;

// titles
$titleH1Color: var(--titleText);
$titleH1FontFamily: var(--titleFontFamily, $fontFamily);
$titleH1FontWeight: $fwb;

$titleH2Color: var(--titleH2Color);
$titleH2FontFamily: var(--titleFontFamily, $fontFamily);
$titleH2FontSize: $fontSize22;

$titleH3FontFamily: var(--titleFontFamily, $fontFamily);
$titleH3Color: var(--primaryText, $mineShaft);

//progress
$progressCurrentTextColor: #181B26;
$progressPrevLineColor: rgba(#181B26, 0.2);
$progressAccentLineColor: $progressPrevLineColor;
$progressAfterLineHeight: 1.2px;
$progressAccentColor: $primaryColor;
$progressNotAccentColor: #F4F4F4;
$progressAccentColor: #181B26;
$progressBarActiveColor: var(--progressBarActiveColor, $primaryColor);
$progressCurrentStepPointColor: var(--progressCurrentStepPointColor);
$progressBarBgColor: var(--progressBarBgColor, $white);
$progressBarPointColor: var(--progressBarPointColor, #F4F4F4);
$progressBarPointBorder: var(--progressBarPointBorder);

// footer
$footerTextColor: rgba(#181B26, 0.4);

//manage

$manageCPSecondaryColor: #181B26;
$manageMainColor: #181B26;
$manageButtonFontSize: $fontSize20;
$mamangeSuccessModalTextFS: $fontSize18;
$manageHeaderSubtitleFS: $fontSize18;
$managePrimaryFontFamily: 'Playfair Display', sans-serif;
$manageSecondaryFontFamily: 'Proxima Nova', sans-serif;
$manageFeatureTitleFF: 'Proxima Nova', sans-serif;
$manageCheckboxBg: #fff;
$managePlansBadgeColor: var(--secondaryColor);
$manageAccentColor: #09737D;
$manageCheckboxChecked: #09737D;
$manageReasonButtonDisabledTextColor: rgba(24, 27, 38, 0.40);
$manageReasonFS: $fontSize15;
$manageReasonLabelFF: $manageSecondaryFontFamily;
$manageCheckmark: white;
$manageBackButtonColor: #181B26;
$manageWarningFontSize: $fontSize15;
$manageWarningColor: #16786C;
$manageTextareaBg: #fff;
$manageModalCloseIcon: #181B26;
$manageTextareaBorder: rgba(24, 27, 38, 0.60);
$manageSubtitleColor: #181B26;
$manageOverlayBg: rgba(61, 107, 101, 0.50);
$manageBg: #F4F4F4;
$manageCurrentPlanBg: #09737D;
$manageContentContainer: (
  'background-color': $white,
  'border-radius': 8px,
  'padding': 3px 10px 15px,
);
$manageTitleColor: #181B26;
$manageSubtitleColor: #181B26;
$manageTitleFontSize: $fontSize26;
$manageBackArrowColor: #181B26;
$manageBorderColor: rgba(45, 45, 45, 0.10);
$manageFeatureTitleFontSize: $fontSize15;
$manageFeatureTextFontSize: $fontSize15;
$manageFeatureBgColor: $white;
$manageFeatureBorder: none;
$manageBorderRadius: 8px;
$manageFeatureCheckBgColor: #E1EEEF;
$manageFeatureCheckIconColor: #09737D;
$manageFeatureSubtitleColor: #181B26;
$manageFeatureSubtitleOpacity: 1;
$manageCancelLinkColor: #181B26;
$manageAlternativeButtonColor: #181B26;
$manageAlternativeButtonFS: $fontSize20;
$manageCancelBorder: 1px solid #181B26;
$manageCancelSuccessTitleColor: #181B26;
$manageCancelSuccessTitleFontSize: $fontSize22;
$manageCancelSuccessPadding: 15px 20px;
$manageCancelSuccessHighlightTextFontWeight: 700;
$manageEmailCancelEmailColor: #27BAA7;

$manageCancelPlanBtn: #181B2699;
$manageCPTitlePrimaryColor: #fff;
$manageCPPrimaryColor: #181B26;
$manageCPTitleStyles: (
  'font-family':$fontFamily
);
$manageCPUpdBadgeStyles: (
  'margin-left': 20px,
  'color': $white,
  'background': linear-gradient(90deg, #F38375 0%, #CE4494 100%)
);
$manageCPMoreIconStyles: (
  'fill': $white,
  'stroke': $white,
  'stroke-width': 0
);

$manageCanceledStatusColor: #C56554;
$manageButtonFontWeight: 700;
$manageAlternativeButtonBorder: 1px solid #181B26;
$manageAlternativeButtonBg: transparent;
$manageLinkBtnFontSize: $fontSize15;
$manageFeaturesTitleFontSize: $fontSize22;
$manageCurrentPlanLeftTextSize: $fontSize15;
$manageCurrentPlanRightTextSize: $fontSize15;
$manageReasonPopupTitleFW: 600;
$manageReasonPopupSubtitleSize: $fontSize18;
$manageReasonButtonDisabledColor: #E1E1E1;
$manageReasonButtonDisabledOpacity: 1;
$manageTitleFontWeight: $fwb;
$manageTitleMarginBottom: 15px;
$manageCurrentPlanTitleWeight: $fwb;
$manageBenefitsTitleWeight: $fwb;
$manageReasonPopupTitleSize: $fontSize22;
$manageReasonPopupButtonFF: 'Proxima Nova', sans-serif;
$manageReasonPopupButtonFS: $fontSize20;
$manageEmailCancelTitleSize: $fontSize26;

$manageReviewStarStroke: rgba(24, 27, 38, 0.4);
$manageReviewStarStrokeActive: #09737D;
$manageReviewStarFillActive: #09737D;

$manageAlternativeButtonBorder: 1px solid #181B26;

$manageInfoPrimaryTextStyles: (
  'font-size': $fontSize15,
  'font-weight': $fwb
);
$manageInfoSecondaryTextStyles: (
  'color': $manageCPPrimaryColor
);
$manageInfoActionTextStyles: (
  'color': $manageAccentColor,
  'font-size': $fontSize15,
  'font-weight': $fwb
);

$terminateTextColor: #181B26;
$terminateConfirmFeatureTitleColor: #181B26;
$terminateBorderColor: $mineShaft;

// plan
$plansSectionTitle: var(--primaryText);
$plansPrimaryBg: var(--plansPrimaryBg);
$plansPrimaryColor: var(--plansPrimaryColor);
$plansPrimaryBorderColor: var(--plansPrimaryBorderColor);
$plansDiscountBgColor: var(--plansDiscountBgColor, $plansPrimaryBorderColor);
$plansPrimaryTitleColor: var(--plansPrimaryTitleColor);
$plansInactivePriceColor: var(--plansInactivePriceColor);
$plansInactiveColor: var(--plansInactiveColor);
$plansBadgeColor: var(--plansBadgeColor);
$plansAccentTitleFW: $fwstb;
$plansSubtitleLineThroughFS: $fontSize14;
$plansProductSubtitleFW: $fwn;
$plansPrimaryTextColor: var(--primaryText);
$plansInactiveTextColor: #A3A4A8;
$plansActiveAfterTrialTextColor: #A3A4A8;
$plansInactiveTitleColor: #A3A4A8;

$plansAccentBorderRadius: var(--plansAccentBorderRadius);
$plansContainerBorderRadius: var(--plansContainerBorderRadius);

$policyCheckBoxTextColor: var(--primaryText);

$fixedButtonDiscountColor: var(--fixedButtonDiscountColor);
$reservedPriceColor: var(--reservedPriceColor);
$checkboxMarkCheckedBackground: var(--checkboxMarkCheckedBackground);
$checkboxMarkBackground: var(--checkboxMarkBackground);

$paymentSafeColor: $mineShaft;

// payment
$paymentModalLineThroughPriceColor: var(--paymentModalLineThroughPriceColor, $primaryColor);
$payModalPrimaryColor: var(--payModalPrimaryColor, $primaryColor);
$payModalDiscountColor: var(--plansPrimaryColor);
$paymentModalLineThroughPriceOpacity: 1;
$paymentModalPersonalDiscountColor: var(--paymentModalPersonalDiscountColor);
$moneyBackBackgroundColor: $white;
$moneyBackTitleColor: var(--moneyBackTitleColor);
$moneyBackIconColor: var(--primaryColor);

//feedback

$feedbackTextsColor: var(--primaryText);
$feedbackStarColor: var(--feedbackStarColor,  var(--primaryColor));

$feedbackTitleStyles: (
  'font-weight': $fwb
);

$feedbackItemTitleStyles: (
  'font-size': $fontSize15,
  'font-family': var(--fontFamily),
  'font-weight': $fwb
);
$feedbackItemTextStyles: (
  'font-size': $fontSize17,
);

//moneyback

$moneyBackTextStyles: (
  'font-size': $fontSize15,
  'letter-spacing': normal
);

// stars
$storeStatsStarColor: var(--primaryColor);
$storeStatsBlockBorder: 1px dashed var(--primaryColor);

$paymentOverlay: #f7f7f7;


//special offer

$soDiscountBlockBg: var(--soDiscountBlockBg);
$soDiscountImageBg: var(--soDiscountImageBg);
$soDiscountAccentTextColor: var(--soDiscountAccentTextColor);
$soCurrentDiscountTextColor: var(--soCurrentDiscountTextColor);
$soPrevDiscountTextColor: var(--soPrevDiscountTextColor);
$soInfoOfferBorderRadius: var(--soInfoOfferBorderRadius);
$soGraphicDescriptionColor: rgba(24, 27, 38, 0.60);
$soTimerPadding: 14px;


$soReservedPriceBg: var(--soReservedPriceBg);

$soInfoAccentTextColor: var(--soInfoAccentTextColor);
$soInfoOfferTextBg: var(--soInfoOfferTextBg);
$soInfoOfferSubtitleFontSize: $fontSize18;
$soModalDescriptionTitleFonSize: $fontSize22;
$soModalDescriptionText: $mineShaft;

// cookie modal

$cookieModalIcon: #E0E0E0;
$cookieModalTextColor: #2D2D2D;
$cookieModalBg: $white;
$cookieModalPrimary: var(--primaryColor);
$cookieModalFootnoteText: rgba(#2D2D2D, 0.40);

$welcomeActiveColor: #09737D;
$welcomeFeatureCheckBgColor: #E1EEEF;

// create account
$createAccountInputIconsColor: rgba(24, 27, 38, 0.6);
$createAccountAsteriskColor: #F38375;
$createAccountNonActiveBtnBg: #E1E1E1;
$createAccountNonActiveBtnText: rgba(24, 27, 38, 0.40);

// reset password
$resetPasswordFieldTextColor: #181B26;
$resetPasswordBorderColor: rgba(24, 27, 38, 0.60);
$resetPasswordPlaceholderColor: rgba(24, 27, 38, 0.40);
$resetPasswordErrorColor: #F38375;
$resetPasswordDisabledOpacity: 1;
$resetPasswordSuccessTextColor: #181B26;
$resetPasswordTitleFontSize: $fontSize26;
$resetPasswordTitleColor: #181B26;
$resetPasswordSuccessPopupTitleColor: #181B26;
$resetPasswordSuccessPopupEmailFontWeight: 600;

$resetPasswordFieldStyles: (
  'width': 100%,
  'border-radius': 6px,
  'border': 1px solid #E1E1E1,
  'background': $white,
  'padding': 15px 40px 15px 15px,
  'font-size': $fontSize20,
  'font-weight': $fwb,
  'line-height': normal,
  'color': $resetPasswordFieldTextColor,
  'position': relative,
  'caret-color': $resetPasswordFieldTextColor,
);
$resetPasswordPlaceholderStyles: (
  'color': $resetPasswordPlaceholderColor,
  'font-size': $fontSize18,
  'font-weight': $fwn,
);
$resetPasswordErrorStyles: (
  'border': 1px solid $resetPasswordErrorColor,
);
$createAccountInputNameStyles: (
  'display': block,
  'color': #181B26,
  'font-size': $fontSize15,
  'font-weight': $fwb,
  'margin-bottom': 5px,
);

//face reading

$faceReadingBgColor: var(--faceReadingBgColor, $white);
$faceReadingBgFooterColor: hsla(227, 23%, 12%, 0.7);

//error popup
$errorPopupSubtitleColor: var(--primaryText);

//email page 
$emailTitleColor: var(--primaryText);

//progressbar

$stepsCounterColor: var(--primaryText);

// create account
$createAccountBorderColor: #E1E1E1;
$createAccountActiveBorderColor: #181b2699;
$createAccountAsteriskColor: $primaryColor;
$createAccountErrorColor: #F38375;
$createAccountInputIconsColor: #181b2699;
$createAccountInputPlaceholderColor: #181b2666;
$createAccountDisabledBtnColor: #E1E1E1;

$createAccountDisabledEmailBg: #E1E1E14D;
$createAccountDisabledEmailText: #181B264D;

$createAccountDisabledBtnOpacity: 1;
$createAccountDisabledTextColor: #181B2666;

$createAccountSubtitleStyles: (
  'font-size': $fontSize18,
  'color': '#181B26'
);
$createAccountInputStyles: (
  'font-size': $fontSize20,
  'border-radius': 6px,
);
$createAccountInputPlaceholderStyles: (
  'font-size': $fontSize18,
  'font-weight': $fwn,
);
$createAccountInputNameStyles: (
  'font-size': $fontSize15,
  'font-weight': $fwb,
);
$createAccountErrorTextStyles: (
  'font-size': $fontSize15
);
$createAccountTitleColor: $primaryText;

$createAccountEmailExistPopupIconColor: #09737D;
