@import 'src/styles';

.tabs {
  --item-width: 70px;
  --padding: 2px;

  position: relative;
  display: flex;
  min-height: 32px;
  height: 32px;
  width: 100%;
  max-width: calc(var(--count) * var(--item-width));
  padding: var(--padding);
  border-radius: 8px;
  background: $white;
  overflow: hidden;
}

.tabs:has(.tab:nth-child(1)) {
  --count: 1;
}

.tabs:has(.tab:nth-child(2)) {
  --count: 2;
}

.tabs:has(.tab:nth-child(3)) {
  --count: 3;
}

.tabs::before {
  content: "";
  position: absolute;
  width: calc(var(--item-width) - 2 * var(--padding));
  height: calc(100% - 2 * var(--padding));
  translate: calc(var(--active) * var(--item-width));
  transition: all .3s ease;
  background-color: $primaryText;
  border-radius: 6px;
}


.tabs:has(.tab:nth-child(1).active) {
  --active: 0;
}

.tabs:has(.tab:nth-child(2).active) {
  --active: 1;
}

.tabs:has(.tab:nth-child(3).active) {
  --active: 2;
}

.tab {
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: var(--item-width);
  padding: 2px 4px;
  font-size: $fontSize15;
  font-family: var(--textFontFamily, $fontFamily);
  color: $primaryText;
  opacity: 0.6;
  transition: all .3s ease;

  &.active {
    font-weight: $fwb;
    color: $white;
    opacity: 1;
  }
}