@import 'core/styles/_normalize';
@import 'core/styles/_global';

@import 'src/styles/fonts';

* {
  overscroll-behavior: none;
}

html,
body {
  height: 100svh;
  width: 100%;
  color: var(--primaryText);
  font-family: var(--fontFamily);
}