@import 'src/styles';

.linkWrapper {
  display: flex;
  align-self: center;
  justify-content: center;
  gap: 4px;
  padding-top: 10px;
  margin-bottom: 20px;

  .link {
    padding: 0;
    font-family: $fontFamily;
    font-size: $fontSize15;
    font-weight: $fwstb;
    color: $primaryText;
  }
}

.modal {
  overflow-x: hidden;
}

.table {
  width: calc(100% + 40px);
}

.contentWrapper {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;

  .title,
  .text {
    font-family: $fontFamily;
    font-size: $fontSize18;
    text-align: left;
  }
}