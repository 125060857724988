@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap&family=Rubik:wght@400;500;600;700');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');

@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/ProximaNova-Bold.eot');
  src: local('Proxima Nova Bold'), local('ProximaNova-Bold'),
    url('../fonts/ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/ProximaNova-Bold.woff2') format('woff2'),
    url('../fonts/ProximaNova-Bold.woff') format('woff'),
    url('../fonts/ProximaNova-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}


@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/ProximaNova-Regular.eot');
  src: local('Proxima Nova Regular'), local('ProximaNova-Regular'),
    url('../fonts/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/ProximaNova-Regular.woff2') format('woff2'),
    url('../fonts/ProximaNova-Regular.woff') format('woff'),
    url('../fonts/ProximaNova-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/ProximaNova-Semibold.eot');
  src: local('Proxima Nova Semibold'), local('ProximaNova-Semibold'),
    url('../fonts/ProximaNova-Semibold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/ProximaNova-Semibold.woff2') format('woff2'),
    url('../fonts/ProximaNova-Semibold.woff') format('woff'),
    url('../fonts/ProximaNova-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}