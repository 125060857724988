@import 'src/styles';

.sizeTableContainer {
  width: 100%;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    margin-bottom: 20px;

    .title {
      margin-bottom: 0;
      font-size: $fontSize26;
    }

    .tabs {
      background-color: var(--mainBg);
    }
  }

  .table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;

    thead {
      border-top: 1px solid #E1E1E1;
    }

    th {
      font-size: $fontSize13;
      font-weight: $fwn;
      white-space: break-spaces;
      opacity: .6;
    }

    td {
      &:first-child {
        font-weight: $fwb;
      }
    }

    th,
    td {
      padding: 10px 0;
      font-size: $fontSize13;
      text-align: left;
      border-bottom: 1px solid #E1E1E1;

      &:first-child {
        padding-left: 20px;
      }

      &:last-child {
        padding-right: 20px;
      }
    }
  }
}